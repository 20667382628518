import { ProgressBarContext } from '../contexts/ProgressBarContext';
import React, { useEffect, useContext } from 'react';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import ReplayIcon from '@mui/icons-material/Replay';
import { Link, IconButton, Box, Typography, Container, Grid, Button } from '@mui/material';
import { SurveyDataContext } from '../contexts/SurveyDataContext';

const Endpage = () => {
  const { setProgress } = useContext(ProgressBarContext);
  const { surveyData, setSurveyData } = useContext(SurveyDataContext);

  useEffect(() => {
    try {
      setProgress(100);
    } catch (error) {
      console.error(error);
    }
  }, [setProgress]);

  const handleComplete = () => {
    window.open('https://app.prolific.com/submissions/complete?cc=CI62LB5D', '_blank');
  };

  const handleFailed = () => {
    window.open('https://app.prolific.com/submissions/complete?cc=C1BRMI8K', '_blank');
  };

  return (
    <Container className="contentDiv center" sx={{ textAlign: 'center', padding: '2rem' }}>
      <Typography variant="h4" gutterBottom>
        {surveyData.attentionCheckFailed ? "Attention check failed ❌": "Attention check passed ✅"}
      </Typography>
      <Typography variant="h4" gutterBottom>
        {surveyData.attentionCheckFailed && surveyData.prolificParticipant ? 
        <Box sx={{ marginTop: '2rem' }}>
        "You have reached the end of the survey. Because you did not pass the attention check that was built into this survey, we can not release the Prolific code.": 
        <Button
          variant="contained"
          sx={{ backgroundColor: 'var(--button-background-color)', color: 'white' }}
          onClick={handleFailed}
        >
          Back to Prolific
        </Button>
        </Box>
        : "Thank you for completing the survey!"}
      </Typography>
      {!surveyData.attentionCheckFailed && surveyData.prolificParticipant ?
      <Box sx={{ marginTop: '2rem' }}>
        <Button
          variant="contained"
          sx={{ backgroundColor: 'var(--button-background-color)', color: 'white' }}
          onClick={handleComplete}
        >
          Claim your Prolific completion code!
        </Button>
        <h3>Or simply use the code: <b>CI62LB5D</b></h3>
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', margin: '2rem 0' }}>
        <ThumbUpIcon sx={{ fontSize: '50px', color: 'var(--button-background-color)' }} />
      </Box>
      {/* <Typography variant="h6" gutterBottom>
        If you have a friend or a relative who likes to help me get more data samples, share this link or press the replay button.
      </Typography> */}
      </Box> 
      : <></> }
      {/* <Typography variant="h6" sx={{ marginBottom: '2rem' }}>
        <Link href={process.env.REACT_APP_URL} sx={{ textDecoration: 'none', color: 'var(--button-background-color)' }}>
          Start Survey
        </Link>
      </Typography>
      <Grid container justifyContent="center">
        <IconButton aria-label="replay" sx={{ color: 'var(--button-background-color)', fontSize: '50px' }}>
          <Link href={process.env.REACT_APP_URL}>
            <ReplayIcon fontSize="inherit" />
          </Link>
        </IconButton>
      </Grid> */}
    </Container>
  );
};

export default Endpage;
