import React, { useState } from 'react';

const ProgressBarContext = React.createContext();
const ProgressBarContextProvider = (props) => {

  const [progress, setProgress] = useState(1);

  return (
    <ProgressBarContext.Provider value={{ progress, setProgress }}>
      {props.children}
    </ProgressBarContext.Provider>
  );
};

export { ProgressBarContextProvider, ProgressBarContext };
