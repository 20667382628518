import React from 'react';
import { Box, Typography } from '@mui/material';

const Footer = () => {
  return (
    <Box className="footer">
      <Typography variant="body1">
        &copy; {new Date().getFullYear()} All rights reserved.
      </Typography>
    </Box>
  );
};

export default Footer;