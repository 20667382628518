import React, { useState, useContext, useEffect } from 'react';
import { Box, InputLabel, MenuItem, FormControl, Select, Button } from '@mui/material/';
import { useNavigate } from 'react-router-dom';
import { ProgressBarContext } from '../contexts/ProgressBarContext';
import { SurveyDataContext } from '../contexts/SurveyDataContext';
import Alert from '@mui/material/Alert';
import { Dialog, DialogActions, DialogTitle, DialogContent, DialogContentText } from '@mui/material';


const DemographicsForm = () => {
  const { surveyData, setSurveyData } = useContext(SurveyDataContext);
  const navigate = useNavigate();
  const { setProgress } = useContext(ProgressBarContext);
  const [showAlert, setShowAlert] = useState(false);
  const [introOpen, setIntroOpen] = useState(true);


  const handleInputChange = (e, fieldName) => {
    const { value } = e.target;
    setSurveyData(prevData => ({
      ...prevData,
      sociodemographics: {
        ...prevData.sociodemographics,
        [fieldName]: value
      }
    }));
  };

  const handleSubmit = () => {
    const { age, gender, country, background, education } = surveyData.sociodemographics;

    if (age && gender && background && education && country) {
      navigate("/iuipc");
      setProgress(15);
    } else {
      setShowAlert(true)
    }
  };

  useEffect(() => {
    setProgress(5);
  }, [setProgress]);

  return (
    <div className='contentDiv'>
      <h1>Demographics</h1>
      {showAlert ? <Alert severity="warning">Please select a value for each field before proceeding.</Alert> : <></>}

      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        <FormControl margin='normal'>
          <InputLabel id="age-select-label">Age</InputLabel>
          <Select
            labelId="age-select-label"
            id="age-select"
            value={surveyData.sociodemographics.age}
            label="Age"
            onChange={(e) => handleInputChange(e, 'age')}
          >
            <MenuItem value={1} className="menuItem">Under 18</MenuItem>
            <MenuItem value={2} className="menuItem">18-24</MenuItem>
            <MenuItem value={3} className="menuItem">25-34</MenuItem>
            <MenuItem value={4} className="menuItem">35-54</MenuItem>
            <MenuItem value={5} className="menuItem">55 and over</MenuItem>
          </Select>
        </FormControl>
        <FormControl margin='normal'>
          <InputLabel id="gender-select-label">Gender</InputLabel>
          <Select
            labelId="gender-select-label"
            id="gender-select"
            value={surveyData.sociodemographics.gender}
            label="Gender"
            onChange={(e) => handleInputChange(e, 'gender')}
          >
            <MenuItem value={"Male"} className="menuItem">Male</MenuItem>
            <MenuItem value={"Female"} className="menuItem">Female</MenuItem>
            <MenuItem value={"Other"} className="menuItem">Other</MenuItem>
          </Select>
        </FormControl>
        <FormControl margin='normal'>
          <InputLabel id="country-select-label">Country</InputLabel>
          <Select
            labelId="country-select-label"
            id="country-select"
            value={surveyData.sociodemographics.country}
            label="Country"
            onChange={(e) => handleInputChange(e, 'country')}
          >
            <MenuItem value="Afghanistan" className="menuItem">Afghanistan</MenuItem>
            <MenuItem value="Albania" className="menuItem">Albania</MenuItem>
            <MenuItem value="Algeria" className="menuItem">Algeria</MenuItem>
            <MenuItem value="Andorra" className="menuItem">Andorra</MenuItem>
            <MenuItem value="Angola" className="menuItem">Angola</MenuItem>
            <MenuItem value="Antigua&Deps" className="menuItem">Antigua & Deps</MenuItem>
            <MenuItem value="Argentina" className="menuItem">Argentina</MenuItem>
            <MenuItem value="Armenia" className="menuItem">Armenia</MenuItem>
            <MenuItem value="Australia" className="menuItem">Australia</MenuItem>
            <MenuItem value="Austria" className="menuItem">Austria</MenuItem>
            <MenuItem value="Azerbaijan" className="menuItem">Azerbaijan</MenuItem>
            <MenuItem value="Bahamas" className="menuItem">Bahamas</MenuItem>
            <MenuItem value="Bahrain" className="menuItem">Bahrain</MenuItem>
            <MenuItem value="Bangladesh" className="menuItem">Bangladesh</MenuItem>
            <MenuItem value="Barbados" className="menuItem">Barbados</MenuItem>
            <MenuItem value="Belarus" className="menuItem">Belarus</MenuItem>
            <MenuItem value="Belgium" className="menuItem">Belgium</MenuItem>
            <MenuItem value="Belize" className="menuItem">Belize</MenuItem>
            <MenuItem value="Benin" className="menuItem">Benin</MenuItem>
            <MenuItem value="Bermuda" className="menuItem">Bermuda</MenuItem>
            <MenuItem value="Bhutan" className="menuItem">Bhutan</MenuItem>
            <MenuItem value="Bolivia" className="menuItem">Bolivia</MenuItem>
            <MenuItem value="BosniaHerzegovina" className="menuItem">Bosnia & Herzegovina</MenuItem>
            <MenuItem value="Botswana" className="menuItem">Botswana</MenuItem>
            <MenuItem value="Brazil" className="menuItem">Brazil</MenuItem>
            <MenuItem value="Brunei" className="menuItem">Brunei</MenuItem>
            <MenuItem value="Bulgaria" className="menuItem">Bulgaria</MenuItem>
            <MenuItem value="Burkina" className="menuItem">Burkina</MenuItem>
            <MenuItem value="Burundi" className="menuItem">Burundi</MenuItem>
            <MenuItem value="Cambodia" className="menuItem">Cambodia</MenuItem>
            <MenuItem value="Cameroon" className="menuItem">Cameroon</MenuItem>
            <MenuItem value="Canada" className="menuItem">Canada</MenuItem>
            <MenuItem value="CapeVerde" className="menuItem">Cape Verde</MenuItem>
            <MenuItem value="CentralAfricanRep" className="menuItem">Central African Rep</MenuItem>
            <MenuItem value="Chad" className="menuItem">Chad</MenuItem>
            <MenuItem value="Chile" className="menuItem">Chile</MenuItem>
            <MenuItem value="China" className="menuItem">China</MenuItem>
            <MenuItem value="Colombia" className="menuItem">Colombia</MenuItem>
            <MenuItem value="Comoros" className="menuItem">Comoros</MenuItem>
            <MenuItem value="Congo" className="menuItem">Congo</MenuItem>
            <MenuItem value="Congo(DemocraticRep)" className="menuItem">Congo (Democratic Rep)</MenuItem>
            <MenuItem value="CostaRica" className="menuItem">Costa Rica</MenuItem>
            <MenuItem value="Croatia" className="menuItem">Croatia</MenuItem>
            <MenuItem value="Cuba" className="menuItem">Cuba</MenuItem>
            <MenuItem value="Cyprus" className="menuItem">Cyprus</MenuItem>
            <MenuItem value="CzechRepublic" className="menuItem">Czech Republic</MenuItem>
            <MenuItem value="Denmark" className="menuItem">Denmark</MenuItem>
            <MenuItem value="Djibouti" className="menuItem">Djibouti</MenuItem>
            <MenuItem value="Dominica" className="menuItem">Dominica</MenuItem>
            <MenuItem value="DominicanRepublic" className="menuItem">Dominican Republic</MenuItem>
            <MenuItem value="EastTimor" className="menuItem">East Timor</MenuItem>
            <MenuItem value="Ecuador" className="menuItem">Ecuador</MenuItem>
            <MenuItem value="Egypt" className="menuItem">Egypt</MenuItem>
            <MenuItem value="ElSalvador" className="menuItem">El Salvador</MenuItem>
            <MenuItem value="EquatorialGuinea" className="menuItem">Equatorial Guinea</MenuItem>
            <MenuItem value="Eritrea" className="menuItem">Eritrea</MenuItem>
            <MenuItem value="Estonia" className="menuItem">Estonia</MenuItem>
            <MenuItem value="Eswatini" className="menuItem">Eswatini</MenuItem>
            <MenuItem value="Ethiopia" className="menuItem">Ethiopia</MenuItem>
            <MenuItem value="Fiji" className="menuItem">Fiji</MenuItem>
            <MenuItem value="Finland" className="menuItem">Finland</MenuItem>
            <MenuItem value="France" className="menuItem">France</MenuItem>
            <MenuItem value="Gabon" className="menuItem">Gabon</MenuItem>
            <MenuItem value="Gambia" className="menuItem">Gambia</MenuItem>
            <MenuItem value="Georgia" className="menuItem">Georgia</MenuItem>
            <MenuItem value="Germany" className="menuItem">Germany</MenuItem>
            <MenuItem value="Ghana" className="menuItem">Ghana</MenuItem>
            <MenuItem value="Greece" className="menuItem">Greece</MenuItem>
            <MenuItem value="Grenada" className="menuItem">Grenada</MenuItem>
            <MenuItem value="Guatemala" className="menuItem">Guatemala</MenuItem>
            <MenuItem value="Guinea" className="menuItem">Guinea</MenuItem>
            <MenuItem value="Guinea-Bissau" className="menuItem">Guinea-Bissau</MenuItem>
            <MenuItem value="Guyana" className="menuItem">Guyana</MenuItem>
            <MenuItem value="Haiti" className="menuItem">Haiti</MenuItem>
            <MenuItem value="Honduras" className="menuItem">Honduras</MenuItem>
            <MenuItem value="Hungary" className="menuItem">Hungary</MenuItem>
            <MenuItem value="Iceland" className="menuItem">Iceland</MenuItem>
            <MenuItem value="India" className="menuItem">India</MenuItem>
            <MenuItem value="Indonesia" className="menuItem">Indonesia</MenuItem>
            <MenuItem value="Iran" className="menuItem">Iran</MenuItem>
            <MenuItem value="Iraq" className="menuItem">Iraq</MenuItem>
            <MenuItem value="Ireland(Republic)" className="menuItem">Ireland (Republic)</MenuItem>
            <MenuItem value="Israel" className="menuItem">Israel</MenuItem>
            <MenuItem value="Italy" className="menuItem">Italy</MenuItem>
            <MenuItem value="IvoryCoast" className="menuItem">Ivory Coast</MenuItem>
            <MenuItem value="Jamaica" className="menuItem">Jamaica</MenuItem>
            <MenuItem value="Japan" className="menuItem">Japan</MenuItem>
            <MenuItem value="Jordan" className="menuItem">Jordan</MenuItem>
            <MenuItem value="Kazakhstan" className="menuItem">Kazakhstan</MenuItem>
            <MenuItem value="Kenya" className="menuItem">Kenya</MenuItem>
            <MenuItem value="Kiribati" className="menuItem">Kiribati</MenuItem>
            <MenuItem value="KoreaSouth" className="menuItem">Korea South</MenuItem>
            <MenuItem value="Kosovo" className="menuItem">Kosovo</MenuItem>
            <MenuItem value="Kuwait" className="menuItem">Kuwait</MenuItem>
            <MenuItem value="Kyrgyzstan" className="menuItem">Kyrgyzstan</MenuItem>
            <MenuItem value="Laos" className="menuItem">Laos</MenuItem>
            <MenuItem value="Latvia" className="menuItem">Latvia</MenuItem>
            <MenuItem value="Lebanon" className="menuItem">Lebanon</MenuItem>
            <MenuItem value="Lesotho" className="menuItem">Lesotho</MenuItem>
            <MenuItem value="Liberia" className="menuItem">Liberia</MenuItem>
            <MenuItem value="Libya" className="menuItem">Libya</MenuItem>
            <MenuItem value="Liechtenstein" className="menuItem">Liechtenstein</MenuItem>
            <MenuItem value="Lithuania" className="menuItem">Lithuania</MenuItem>
            <MenuItem value="Luxembourg" className="menuItem">Luxembourg</MenuItem>
            <MenuItem value="Macedonia" className="menuItem">Macedonia</MenuItem>
            <MenuItem value="Madagascar" className="menuItem">Madagascar</MenuItem>
            <MenuItem value="Malawi" className="menuItem">Malawi</MenuItem>
            <MenuItem value="Malaysia" className="menuItem">Malaysia</MenuItem>
            <MenuItem value="Maldives" className="menuItem">Maldives</MenuItem>
            <MenuItem value="Mali" className="menuItem">Mali</MenuItem>
            <MenuItem value="Malta" className="menuItem">Malta</MenuItem>
            <MenuItem value="MarshallIslands" className="menuItem">Marshall Islands</MenuItem>
            <MenuItem value="Mauritania" className="menuItem">Mauritania</MenuItem>
            <MenuItem value="Mauritius" className="menuItem">Mauritius</MenuItem>
            <MenuItem value="Mexico" className="menuItem">Mexico</MenuItem>
            <MenuItem value="Micronesia" className="menuItem">Micronesia</MenuItem>
            <MenuItem value="Moldova" className="menuItem">Moldova</MenuItem>
            <MenuItem value="Monaco" className="menuItem">Monaco</MenuItem>
            <MenuItem value="Mongolia" className="menuItem">Mongolia</MenuItem>
            <MenuItem value="Montenegro" className="menuItem">Montenegro</MenuItem>
            <MenuItem value="Morocco" className="menuItem">Morocco</MenuItem>
            <MenuItem value="Mozambique" className="menuItem">Mozambique</MenuItem>
            <MenuItem value="Myanmar" className="menuItem">Myanmar</MenuItem>
            <MenuItem value="Namibia" className="menuItem">Namibia</MenuItem>
            <MenuItem value="Nauru" className="menuItem">Nauru</MenuItem>
            <MenuItem value="Nepal" className="menuItem">Nepal</MenuItem>
            <MenuItem value="Netherlands" className="menuItem">Netherlands</MenuItem>
            <MenuItem value="NewZealand" className="menuItem">New Zealand</MenuItem>
            <MenuItem value="Nicaragua" className="menuItem">Nicaragua</MenuItem>
            <MenuItem value="Niger" className="menuItem">Niger</MenuItem>
            <MenuItem value="Nigeria" className="menuItem">Nigeria</MenuItem>
            <MenuItem value="Norway" className="menuItem">Norway</MenuItem>
            <MenuItem value="Oman" className="menuItem">Oman</MenuItem>
            <MenuItem value="Pakistan" className="menuItem">Pakistan</MenuItem>
            <MenuItem value="Palau" className="menuItem">Palau</MenuItem>
            <MenuItem value="Palestine" className="menuItem">Palestine</MenuItem>
            <MenuItem value="Panama" className="menuItem">Panama</MenuItem>
            <MenuItem value="PapuaNewGuinea" className="menuItem">Papua New Guinea</MenuItem>
            <MenuItem value="Paraguay" className="menuItem">Paraguay</MenuItem>
            <MenuItem value="Peru" className="menuItem">Peru</MenuItem>
            <MenuItem value="Philippines" className="menuItem">Philippines</MenuItem>
            <MenuItem value="Poland" className="menuItem">Poland</MenuItem>
            <MenuItem value="Portugal" className="menuItem">Portugal</MenuItem>
            <MenuItem value="Qatar" className="menuItem">Qatar</MenuItem>
            <MenuItem value="Romania" className="menuItem">Romania</MenuItem>
            <MenuItem value="RussianFederation" className="menuItem">Russian Federation</MenuItem>
            <MenuItem value="Rwanda" className="menuItem">Rwanda</MenuItem>
            <MenuItem value="StKitts&Nevis" className="menuItem">St Kitts & Nevis</MenuItem>
            <MenuItem value="StLucia" className="menuItem">St Lucia</MenuItem>
            <MenuItem value="SaintVincent&theGrenadines" className="menuItem">Saint Vincent & the Grenadines</MenuItem>
            <MenuItem value="Samoa" className="menuItem">Samoa</MenuItem>
            <MenuItem value="SanMarino" className="menuItem">San Marino</MenuItem>
            <MenuItem value="SaoTome&Principe" className="menuItem">Sao Tome & Principe</MenuItem>
            <MenuItem value="SaudiArabia" className="menuItem">Saudi Arabia</MenuItem>
            <MenuItem value="Senegal" className="menuItem">Senegal</MenuItem>
            <MenuItem value="Serbia" className="menuItem">Serbia</MenuItem>
            <MenuItem value="Seychelles" className="menuItem">Seychelles</MenuItem>
            <MenuItem value="SierraLeone" className="menuItem">Sierra Leone</MenuItem>
            <MenuItem value="Singapore" className="menuItem">Singapore</MenuItem>
            <MenuItem value="Slovakia" className="menuItem">Slovakia</MenuItem>
            <MenuItem value="Slovenia" className="menuItem">Slovenia</MenuItem>
            <MenuItem value="SolomonIslands" className="menuItem">Solomon Islands</MenuItem>
            <MenuItem value="Somalia" className="menuItem">Somalia</MenuItem>
            <MenuItem value="SouthAfrica" className="menuItem">South Africa</MenuItem>
            <MenuItem value="SouthSudan" className="menuItem">South Sudan</MenuItem>
            <MenuItem value="Spain" className="menuItem">Spain</MenuItem>
            <MenuItem value="SriLanka" className="menuItem">Sri Lanka</MenuItem>
            <MenuItem value="Sudan" className="menuItem">Sudan</MenuItem>
            <MenuItem value="Suriname" className="menuItem">Suriname</MenuItem>
            <MenuItem value="Sweden" className="menuItem">Sweden</MenuItem>
            <MenuItem value="Switzerland" className="menuItem">Switzerland</MenuItem>
            <MenuItem value="Syria" className="menuItem">Syria</MenuItem>
            <MenuItem value="Taiwan" className="menuItem">Taiwan</MenuItem>
            <MenuItem value="Tajikistan" className="menuItem">Tajikistan</MenuItem>
            <MenuItem value="Tanzania" className="menuItem">Tanzania</MenuItem>
            <MenuItem value="Thailand" className="menuItem">Thailand</MenuItem>
            <MenuItem value="Togo" className="menuItem">Togo</MenuItem>
            <MenuItem value="Tonga" className="menuItem">Tonga</MenuItem>
            <MenuItem value="Trinidad&Tobago" className="menuItem">Trinidad & Tobago</MenuItem>
            <MenuItem value="Tunisia" className="menuItem">Tunisia</MenuItem>
            <MenuItem value="Turkey" className="menuItem">Turkey</MenuItem>
            <MenuItem value="Turkmenistan" className="menuItem">Turkmenistan</MenuItem>
            <MenuItem value="Tuvalu" className="menuItem">Tuvalu</MenuItem>
            <MenuItem value="Uganda" className="menuItem">Uganda</MenuItem>
            <MenuItem value="Ukraine" className="menuItem">Ukraine</MenuItem>
            <MenuItem value="UnitedArabEmirates" className="menuItem">United Arab Emirates</MenuItem>
            <MenuItem value="UnitedKingdom" className="menuItem">United Kingdom</MenuItem>
            <MenuItem value="UnitedStates" className="menuItem">United States</MenuItem>
            <MenuItem value="Uruguay" className="menuItem">Uruguay</MenuItem>
            <MenuItem value="Uzbekistan" className="menuItem">Uzbekistan</MenuItem>
            <MenuItem value="Vanuatu" className="menuItem">Vanuatu</MenuItem>
            <MenuItem value="VaticanCity" className="menuItem">Vatican City</MenuItem>
            <MenuItem value="Venezuela" className="menuItem">Venezuela</MenuItem>
            <MenuItem value="Vietnam" className="menuItem">Vietnam</MenuItem>
            <MenuItem value="Yemen" className="menuItem">Yemen</MenuItem>
            <MenuItem value="Zambia" className="menuItem">Zambia</MenuItem>
            <MenuItem value="Zimbabwe" className="menuItem">Zimbabwe</MenuItem>
          </Select>
        </FormControl>

        <FormControl margin='normal'>
          <InputLabel id="background-select-label">Background</InputLabel>
          <Select
            labelId="background-select-label"
            id="background-select"
            value={surveyData.sociodemographics.background}
            label="Background"
            onChange={(e) => handleInputChange(e, 'background')}
          >
            <MenuItem value={"Student"} className="menuItem">Student</MenuItem>
            <MenuItem value={"Research"} className="menuItem">Academia/Research</MenuItem>
            <MenuItem value={"Industry"} className="menuItem">Industry Professional</MenuItem>
            <MenuItem value={"Self-Employed"} className="menuItem">Entrepreneur</MenuItem>
            <MenuItem value={"Unemployed"} className="menuItem">Unemployed/Looking for Work</MenuItem>
            <MenuItem value={"Other"} className="menuItem">Other</MenuItem>
          </Select>
        </FormControl>
        <FormControl margin='normal'>
          <InputLabel id="education-select-label">Education</InputLabel>
          <Select
            labelId="education-select-label"
            id="education-select"
            value={surveyData.sociodemographics.education}
            label="Education"
            onChange={(e) => handleInputChange(e, 'education')}
          >
            <MenuItem value={"School"} className="menuItem">High School</MenuItem>
            <MenuItem value={"Bachelor"} className="menuItem">Bachelor's Degree</MenuItem>
            <MenuItem value={"Master"} className="menuItem">Master's Degree</MenuItem>
            <MenuItem value={"Doctorate"} className="menuItem">Doctorate</MenuItem>
            <MenuItem value={"Apprenticeship"} className="menuItem">Apprenticeship or Qualification/Training</MenuItem>
            <MenuItem value={"Other"} className="menuItem">Other</MenuItem>
          </Select>
        </FormControl>
      </Box>
      <div className='buttonGroup'>
        <Button variant="outlined" onClick={() => navigate("/")}>Back</Button>
        <Button variant="outlined" onClick={handleSubmit}>Next</Button>
      </div>
      <Dialog open={introOpen} onClose={() => setIntroOpen(false)}>
        <DialogTitle sx={{ fontWeight: 'bold' }}>Getting started</DialogTitle>
        <DialogContent>
          <DialogContentText style={{ color: 'gray' }}>
            We greatly appreciate your participation in this survey! Your responses are extremely valuable to us and will help improve our research.
            We kindly ask that you pay close attention to each vignette and provide honest and thoughtful answers.
            Thank you once again for your time and contribution!
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIntroOpen(false)} color="primary">Start</Button>
        </DialogActions>
      </Dialog>

    </div>
  );
};

export default DemographicsForm;
