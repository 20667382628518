import React from 'react';
import Box from '@mui/material/Box';
import Slider from '@mui/material/Slider';

const SurveyItem = ({ props, sliderValue, onSliderChange }) => {
  const handleSliderChange = (e, newValue) => {
    onSliderChange(newValue); // Call parent function with selected value
  };

  return (
    <div className='center'>
      {/* <h2>Vignette {props.scenario}</h2> */}
      {/* <p>"{props.content[sliderValue - 1].text}"</p> */}
      <Box sx={{ width: '60%' }}>
        <Slider
          aria-label="Temperature"
          value={sliderValue}
          valueLabelDisplay="auto"
          shiftStep={1}
          step={1}
          marks
          min={1}
          max={5}
          onChange={handleSliderChange}
        />
      </Box>
    </div>
  );
};

export default SurveyItem;
