// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.App {
  text-align: center;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  justify-content: center;
}

.Default {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  flex: 1 1;
}

.Header {
  display: flex;
  align-items: center;
  flex-direction: column;
  border-bottom: 1.5px solid gray;
  font-size: medium;
}

.center {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.buttonGroup {
  justify-content: space-between;
  width: 100%;
  display: flex;
  flex-direction: row;
  margin-top: 40px;
  margin-bottom: 40px;
}

.contentDiv {
  width: 60%; /* Set width to 60% */
  display: flex;
  flex-direction: column;
  margin: auto; /* Center the div itself */
}

.fontBig {
  font-size: x-large; 
}`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,aAAa;EACb,sBAAsB;EACtB,iBAAiB;EACjB,uBAAuB;AACzB;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,uBAAuB;EACvB,WAAW;EACX,SAAO;AACT;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,sBAAsB;EACtB,+BAA+B;EAC/B,iBAAiB;AACnB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,sBAAsB;AACxB;;AAEA;EACE,8BAA8B;EAC9B,WAAW;EACX,aAAa;EACb,mBAAmB;EACnB,gBAAgB;EAChB,mBAAmB;AACrB;;AAEA;EACE,UAAU,EAAE,qBAAqB;EACjC,aAAa;EACb,sBAAsB;EACtB,YAAY,EAAE,0BAA0B;AAC1C;;AAEA;EACE,kBAAkB;AACpB","sourcesContent":[".App {\n  text-align: center;\n  display: flex;\n  flex-direction: column;\n  min-height: 100vh;\n  justify-content: center;\n}\n\n.Default {\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  width: 100%;\n  flex: 1;\n}\n\n.Header {\n  display: flex;\n  align-items: center;\n  flex-direction: column;\n  border-bottom: 1.5px solid gray;\n  font-size: medium;\n}\n\n.center {\n  display: flex;\n  align-items: center;\n  flex-direction: column;\n}\n\n.buttonGroup {\n  justify-content: space-between;\n  width: 100%;\n  display: flex;\n  flex-direction: row;\n  margin-top: 40px;\n  margin-bottom: 40px;\n}\n\n.contentDiv {\n  width: 60%; /* Set width to 60% */\n  display: flex;\n  flex-direction: column;\n  margin: auto; /* Center the div itself */\n}\n\n.fontBig {\n  font-size: x-large; \n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
