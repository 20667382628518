import '../App.css';
import LinearProgressWithLabel from './LinearProgressWithLabel';
import { ProgressBarContext } from '../contexts/ProgressBarContext';
import { ThemeContext } from '../contexts/ThemeContext';
import React, { useContext } from 'react';
import { IconButton, Box } from '@mui/material';
import Brightness4Icon from '@mui/icons-material/Brightness4';
import Brightness7Icon from '@mui/icons-material/Brightness7';
import Footer from './Footer';

const Default = ({ children }) => {
  const { progress } = useContext(ProgressBarContext);
  const { theme, toggleTheme } = useContext(ThemeContext);

  return (
    <div className="App">
      <div className='Header'>
        <h3>Understanding Textual Data Sharing</h3>
        <LinearProgressWithLabel value={progress} />
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: '20px' }}>
          <IconButton
            onClick={toggleTheme}
            sx={{
              fontSize: '2rem',
              color: 'var(--text-color)',
              marginRight: '10px',
            }}
          >
            {theme === 'light' ? <Brightness4Icon fontSize="inherit" /> : <Brightness7Icon fontSize="inherit" />}
          </IconButton>
          <span>{theme === 'light' ? 'Dark' : 'Light'} Theme</span>
        </Box>
      </div>
      <div className="Default">{children}</div>
      <Footer />
    </div>
  );
};

export default Default;
