import React, { useState, createContext, useEffect } from 'react';

const SurveyDataContext = createContext();

const SurveyDataProvider = ({ children }) => {
  const initialState = {
    sociodemographics: {
      gender: "",
      age: "",
      country: "",
      background: "", 
      education: "",
    },
    IUIPC: {
      control: {
        one: 0,
        two: 0,
        three: 0
      },
      awareness: {
        one: 0,
        two: 0,
        three: 0
      },
      col: {
        one: 0,
        two: 0,
        three: 0,
        four: 0,
      }
    },
    vignettes: [],
    startTime: null,
    endTime: null,
    attentionCheckFailed: false,
    sessionId: "",
    comments0: "",
    comments1: "",
    comments2: "",
    prolificParticipant: null
  };

  const [surveyData, setSurveyData] = useState(() => {
    const storedData = localStorage.getItem('surveyData');
    return storedData ? JSON.parse(storedData) : initialState;
  });

  useEffect(() => {
    localStorage.setItem('surveyData', JSON.stringify(surveyData));
  }, [surveyData]);

  return (
    <SurveyDataContext.Provider value={{ surveyData, setSurveyData }}>
      {children}
    </SurveyDataContext.Provider>
  );
};

export { SurveyDataContext, SurveyDataProvider };