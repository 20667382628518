// IntroComponent.js
import React, { useEffect, useContext, useState } from 'react';
import { Box } from '@mui/system';
import '../App.css';
import { Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { ProgressBarContext } from '../contexts/ProgressBarContext';
import OppositeContentTimeline from './Timeline';
import { SurveyDataContext } from '../contexts/SurveyDataContext';
import { Dialog, DialogActions, DialogTitle, DialogContent, DialogContentText } from '@mui/material';

const IntroComponent = () => {
  const navigate = useNavigate();
  const { setProgress } = useContext(ProgressBarContext);
  const { surveyData, setSurveyData } = useContext(SurveyDataContext);
  const [selectionOpen, setSelectionOpen] = useState(surveyData.prolificParticipant === null);
  const [disclaimerOpen, setDisclaimerOpen] = useState(false);
  const [prolificParticipant, setProlificParticipant] = useState(false);

  useEffect(() => {
    try {
      setProgress(1);
    } catch (error) {
      console.error(error);
    }
  }, [setProgress]);

  const handleStart = () => {
    setDisclaimerOpen(false);
    setSurveyData(prevData => ({
      ...prevData,
      startTime: new Date().toISOString(),
      prolificParticipant: prolificParticipant,
    }));
    navigate("/demographics");
  };

  const handleSelection = (isProlific) => {
    setProlificParticipant(isProlific);
    setSelectionOpen(false);
  };

  const openDisclaimer = () => {
    setDisclaimerOpen(true);
  };

  return (
    <>
      <Box className="contentDiv center fontBig">
        <h1>Welcome to our Survey!</h1>
        <p>The survey consists of a series of questions designed to understand your data sharing preferences. <br></br> It should take approximately 10 minutes to complete.</p>
        {/* <p>Each scenario is straightforward and should only take a few moments to answer. Please answer each question to the best of your ability.</p> */}
        <OppositeContentTimeline />
      </Box>
      <div className="contentDiv center">
        <Button variant="outlined" onClick={openDisclaimer}>Start the survey</Button>
      </div>
      <Dialog open={selectionOpen}>
        <DialogTitle sx={{ fontWeight: 'bold' }}>Select Your Participant Type</DialogTitle>
        <DialogContent>
          <DialogContentText style={{ color: 'gray' }}>
            Please select whether you are a Prolific participant or participating as a friend/family member.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleSelection(true)} color="primary">Prolific</Button>
          <Button onClick={() => handleSelection(false)} color="primary">Family/Friends</Button>
        </DialogActions>
      </Dialog>
      <Dialog open={disclaimerOpen} onClose={() => setDisclaimerOpen(false)}>
        <DialogTitle sx={{ fontWeight: 'bold' }}>Disclaimer & Getting Started</DialogTitle>
        <DialogContent>
          <DialogContentText style={{ color: 'gray' }}>
            Dear participant, <br></br> <br></br> By conducting this survey, you agree to the recording and evaluation of your responses for research purposes. Please note that we do not collect any information other than responses you make and the total completion time. <br/> <br/> Additionally, <b>an attention check</b> question will be included to determine whether your response will be considered valid for evaluation. If you pass the attention check, your response will be used in the final analysis.
            {prolificParticipant && (
              <span> Passing the attention check is required to receive a Prolific payment code. <br/> <br/> By clicking 'Start', you agree to these terms of service.</span>
            )}
            <br></br> <br></br> Thank you for your participation!
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleStart} color="primary">Start</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default IntroComponent;