import React, { useState, useContext, useEffect } from 'react';
import { Box, Button } from '@mui/material/';
import { useNavigate } from 'react-router-dom';
import { ProgressBarContext } from '../contexts/ProgressBarContext';
import { SurveyDataContext } from '../contexts/SurveyDataContext';
import RadioGroupRating from './RadioGroupRating';
import Alert from '@mui/material/Alert';

const IUIPCForm = () => {
  const { surveyData, setSurveyData } = useContext(SurveyDataContext);
  const navigate = useNavigate();
  const { setProgress } = useContext(ProgressBarContext);
  const [showAlert, setShowAlert] = useState(false);


  const handleInputChange = (value, fieldName, category) => {
    setSurveyData(prevData => ({
      ...prevData,
      IUIPC: {
        ...prevData.IUIPC,
        [category]: {
          ...prevData.IUIPC[category],
          [fieldName]: value
        }
      }
    }));
  };

  const handleSubmit = () => {
    const isAllFieldsNonZero = (fields) => fields.every(field => field !== 0 && field !== null);
    const { control, awareness, col } = surveyData.IUIPC;

    if (
      isAllFieldsNonZero(Object.values(control)) &&
      isAllFieldsNonZero(Object.values(awareness)) &&
      isAllFieldsNonZero(Object.values(col))
    ) {
      navigate("/survey");
      setProgress(25);
    }
    else {
      setShowAlert(true);
      window.scrollTo({ top: 0, behavior: 'smooth' }); // Scroll to top
    }
  };

  useEffect(() => {
    setProgress(15);
  }, [setProgress]);

  return (
    <div className='contentDiv center'>
      <h1>User Privacy Concerns</h1>
      {showAlert ? <Alert severity="warning">Please select a value for each field before proceeding.</Alert> : <></>}
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
        <p style={{fontSize:18}}><b>Instructions:</b> below you will find a series of statements regarding data privacy. <br></br> Please select the degree to which you agree with the following statements.</p>
        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
          <p>Strongly Disagree</p>
          <RadioGroupRating value={5} onChange={() => { }} />
          <p>Strongly Agree</p>
        </Box>
        <hr />
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
          {/* <h2>Control</h2> */}
          <p>Consumer online privacy is really a matter of consumers' right to exercise control and autonomy over decisions about how their information is collected, used, and shared.</p>
          <RadioGroupRating value={surveyData.IUIPC.control.one} onChange={(value) => handleInputChange(value, 'one', 'control')} />
          <p>Consumer control of personal information lies at the heart of consumer privacy.</p>
          <RadioGroupRating value={surveyData.IUIPC.control.two} onChange={(value) => handleInputChange(value, 'two', 'control')} />
          <p>I believe that online privacy is invaded when control is lost or unwillingly reduced as a result of a marketing transaction.</p>
          <RadioGroupRating value={surveyData.IUIPC.control.three} onChange={(value) => handleInputChange(value, 'three', 'control')} />
          <hr />
          {/* <h2>Awareness</h2> */}
          <p>Companies seeking information online should disclose the way the data are collected, processed, and used.</p>
          <RadioGroupRating value={surveyData.IUIPC.awareness.one} onChange={(value) => handleInputChange(value, 'one', 'awareness')} />
          <p>A good consumer online privacy policy should have a clear and conspicuous disclosure.</p>
          <RadioGroupRating value={surveyData.IUIPC.awareness.two} onChange={(value) => handleInputChange(value, 'two', 'awareness')} />
          <p>It is very important to me that I am aware and knowledgeable about how my personal information will be used.</p>
          <RadioGroupRating value={surveyData.IUIPC.awareness.three} onChange={(value) => handleInputChange(value, 'three', 'awareness')} />
          <hr />
          {/* <h2>Collection</h2> */}
          <p>It usually bothers me when online companies ask me for personal information.</p>
          <RadioGroupRating value={surveyData.IUIPC.col.one} onChange={(value) => handleInputChange(value, 'one', 'col')} />
          <p>When online companies ask me for personal information, I sometimes think twice before providing it.</p>
          <RadioGroupRating value={surveyData.IUIPC.col.two} onChange={(value) => handleInputChange(value, 'two', 'col')} />
          <p>It bothers me to give personal information to so many online companies.</p>
          <RadioGroupRating value={surveyData.IUIPC.col.three} onChange={(value) => handleInputChange(value, 'three', 'col')} />
          <p>I'm concerned that online companies are collecting too much personal information about me.</p>
          <RadioGroupRating value={surveyData.IUIPC.col.four} onChange={(value) => handleInputChange(value, 'four', 'col')} />
        </Box>
      </Box>
      <div className='buttonGroup'>
        <Button variant="outlined" onClick={() => navigate("/demographics")}>Back</Button>
        <Button variant="outlined" onClick={handleSubmit}>Next</Button>
      </div>
    </div>
  );
};

export default IUIPCForm;
