import React from 'react';
import './App.css';
// App.js
import { Route, Routes } from 'react-router-dom';
import IntroComponent from './components/IntroComponent';
import DemographicsForm from './components/DemographicsForm';
import SurveyContainer from './components/SurveyContainer';
import FeedbackForm from './components/FeebackForm';
import IUIPCForm from './components/IUIPCForm';
import Endpage from './pages/Endpage';
import Default from './layout/Default';
import { ProgressBarContextProvider } from './contexts/ProgressBarContext';
import { SurveyDataProvider } from './contexts/SurveyDataContext';

const App = () => {

  return (
    <div>
      <ProgressBarContextProvider>
      <SurveyDataProvider>
        <Default>
          {/* Routes */}
          <Routes>
            <Route path="/" element={<IntroComponent />}></Route>
            <Route path="/demographics" element={<DemographicsForm />}></Route>
            <Route path="/iuipc" element={<IUIPCForm />}></Route>
            <Route path="/survey" element={<SurveyContainer />}></Route>
            <Route path="/feedback" element={<FeedbackForm />}></Route>
            <Route path="/end" element={<Endpage />}></Route>
          </Routes>
        </Default>
        </SurveyDataProvider>
      </ProgressBarContextProvider>
    </div>
  );
};

export default App;

